import { API } from '../../const';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from '../../services/api';
import actionTypes from './profile.types';

export function* getProfile(action) {
  try {
    const { cb } = action;
    const res = yield call(() => api.get(`${API.USER_PROFILE}`));
    if (res.status === 200) {
      yield put({ type: actionTypes.SET_PROFILE, payload: res.data.data });
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* editProfile(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.USER_PROFILE}`, data));
    if (res.status === 200) {
      cb(res.data.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* getBranches(action) {
  try {
    const { cb } = action;
    const res = yield call(() => api.get(`${API.BRANCH}`));
    if (res.status === 200) {
      console.log(res.data.data, 'res.data');
      yield put({ type: actionTypes.SET_CENTERS, payload: res.data });
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

function* watchProfileSaga() {
  yield takeEvery(actionTypes.GET_PROFILE, getProfile);
  yield takeEvery(actionTypes.EDIT_PROFILE, editProfile);
  yield takeEvery(actionTypes.GET_CENTERS, getBranches);
}

export default watchProfileSaga;
