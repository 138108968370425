export const summaryHeaders = [
  {
    text: 'Amount',
    alignment: 'name',
    sortable: false,
    key: 'name'
  },
  {
    text: 'Room',
    alignment: 'room',
    sortable: false,
    key: 'room'
  },
  {
    text: 'Procedure',
    alignment: 'procedure',
    sortable: false,
    key: 'procedure'
  },
  {
    text: 'Date/Time',
    alignment: 'date',
    sortable: false,
    key: 'date'
  }
];

export const headers = [
  {
    text: 'Name',
    alignment: 'center',
    sortable: false,
    key: 'name'
  },
  {
    text: 'Visit ID',
    alignment: 'visit_id',
    sortable: false,
    key: 'visit_id'
  },
  {
    text: 'Room',
    alignment: 'center',
    sortable: false,
    key: 'room'
  },
  {
    text: 'Date',
    alignment: 'center',
    sortable: false,
    key: 'date'
  },
  {
    text: 'Time In',
    alignment: 'center',
    sortable: false,
    key: 'time-in'
  },
  {
    text: 'Status',
    alignment: 'center',
    sortable: false,
    key: 'status'
  },
  {
    text: 'Results',
    alignment: 'center',
    sortable: false,
    key: 'results'
  },
  {
    text: '',
    alignment: 'center',
    sortable: false,
    key: 'action'
  }
];

export const singleQueueHeaders = [
  {
    text: 'Amount',
    alignment: 'name',
    sortable: false,
    key: 'name'
  },
  {
    text: 'Room',
    alignment: 'room',
    sortable: false,
    key: 'room'
  },
  {
    text: 'Procedure',
    alignment: 'procedure',
    sortable: false,
    key: 'procedure'
  },
  {
    text: 'Procedure Date',
    alignment: 'date',
    sortable: false,
    key: 'date'
  },
  {
    text: 'Status',
    alignment: 'status',
    sortable: false,
    key: 'status'
  },
  {
    text: '',
    alignment: 'action',
    sortable: false,
    key: 'action'
  }
];
export const singleQueueData = [
  {
    id: '1',
    amount: '30,000',
    room: 'Ultrasound',
    procedure: 'Full abdominal scan',
    procedure_date: 'Sept. 26, 2022',
    status: 'Not paid'
  },
  {
    id: '2',
    amount: '30,000',
    room: 'Ultrasound',
    procedure: 'Full abdominal scan',
    procedure_date: 'Sept. 26, 2022',
    status: 'Fully paid'
  },
  {
    id: '3',
    amount: '30,000',
    room: 'Ultrasound',
    procedure: 'Full abdominal scan',
    procedure_date: 'Sept. 26, 2022',
    status: 'Fully paid'
  },
  {
    id: '4',
    amount: '30,000',
    room: 'Ultrasound',
    procedure: 'Full abdominal scan',
    procedure_date: 'Sept. 26, 2022',
    status: 'Not paid'
  },
  {
    id: '5',
    amount: '30,000',
    room: 'Ultrasound',
    procedure: 'Full abdominal scan',
    procedure_date: 'Sept. 26, 2022',
    status: 'Not paid'
  }
];
